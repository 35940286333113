import { useState, useEffect } from 'react'
import { useAppState } from '../contexts/appState';
import getDictionary from '../services/getDictionary'
import Anime, { anime } from 'react-anime';

const LetterPicker = () => {

  const { appState, updateAppState } = useAppState();
  const [ animState, setAnimState ] = useState(0);
 
  const doGetDictionary = (letter:string) =>{
    console.dir("loading dictionary from letter picker:", letter);
    let file = `/words/${letter}.json`;
    getDictionary(file)
			.then(data =>
			updateAppState({type: 'set_dictionary', value: data}));
  }

  useEffect(() => {
    setAnimState(animState + 1);
  }, []); //eslint-disable-line

  const renderLetter =(letter:string, key:number) => {

      return <button 
              key={key} 
              onClick={() => (appState.currLetters.length === 0)? 
                doGetDictionary(letter)
                 : updateAppState({type: 'set_selected_letter', value: letter})}
              >{letter.toUpperCase()} </button>
  }

  let letters = appState.alphabet.map((letter:string, i:number) => {     
      return (renderLetter(letter, i));
    });

  return(
 
      <div id="letters">
        <Anime delay={anime.stagger(30)} scale={appState.currLetters.length === 0 ?[ 0.01, 1.0 ] : [1.0, 1.0]}>
          {letters}
        </Anime>
      </div>

  );

}

export default LetterPicker;