import { useCallback } from 'react'
import { useAppState } from '../contexts/appState'
import LetterPicker from './LetterPicker'
import ShowCPUWord from './ShowCPUWord'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

const QuitButton = styled.button`
    margin-top:10px;
`

const Game = ({ children }: any) => {

  const { appState, updateAppState } = useAppState();
  const navigate = useNavigate();

  const playAgain = useCallback(() => {
    updateAppState({type: 'reset', value: true});
    navigate('/');
  }, [updateAppState, navigate]);

  const quit = useCallback(() => {
    updateAppState({type: 'set_end_game_message', value: `Oh no, try again?`});
    navigate('/');
  }, [updateAppState, navigate]);
 
  if(appState.endGameMessage === null){
    return (
        <div> 
            {appState.playerCount > 1 &&
              <h1>PLAYER {appState.userToPlay + 1}</h1>
            }
            { appState.playerCount === 1 && appState.userToPlay !== 0 ? <h4>Computer is choosing a letter</h4> : <h4>Please choose a letter. You must not complete a word.</h4>}
            { (appState.playerCount === 1 && appState.userToPlay === 1) 
            ?
              <></>
            :
              <LetterPicker /> }
              <p id="CurrentLetters">{ appState.currLetters }</p>
            <ShowCPUWord />
            <QuitButton onClick={quit}>Quit!</QuitButton>
        </div>
    );
  } else {
    return (
        <div>  
            <h2>{ appState.endGameMessage }</h2>
            <h3>"{ appState.currLetters }"</h3>
            <button className="text-button" onClick={playAgain}>Play Again!</button>
        </div>
    );
  }
	
};

export default Game;
