import { useCallback } from 'react'
import { useEffectOnce } from 'react-use'
import { useAppState } from '../contexts/appState'
import { PlayerSelector } from './PlayerSelector'
import { styled } from 'styled-components'
import { useNavigate } from "react-router-dom";

const SetupGrid = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns: 30% 70%;
    grid-gap: 0px;
`

const GoButton = styled.button`
    margin-top:10vh;
`


const Setup = () => {

  const { updateAppState } = useAppState();
  const navigate = useNavigate();

  useEffectOnce(() => {
    updateAppState({type: 'reset', value: true});
  });

  const doGo = useCallback(() => {
    navigate('/play');
  }, [navigate]);

  return(
 
      <div id="setup">
        <SetupGrid>
            <p>Choose Players</p>
            <PlayerSelector />
        </SetupGrid>
        <GoButton onClick={doGo}>PLAY!</GoButton>
      </div>

  );

}

export default Setup;