import './App.scss';
import { StateProvider } from './contexts/appState';
import { Routes, Route } from 'react-router-dom'
import Setup from './components/Setup';
import Game from './components/Game';

function App() {
  
  return (
    <div className="App">
      <StateProvider>
        <h1>Letter Me</h1>
        <Routes>
          <Route path="/" Component={Setup} />
          <Route path="/play" Component={Game} />
        </Routes>
      </StateProvider>
    </div>
  );
	
};

export default App;

/*
export default App;

  handleLetterAdd(letter) {
    // combine letter with previous
    let currLetters = this.state.currLetters;
    currLetters += letter;

    if(this.checkWord(currLetters)){

    }

    
  
  }

  checkWord(currLetters){
    // determine if the new combination makes a word, if it does end game
    
    
  }


}
*/
