import { useState } from "react";
import { useAppState } from '../contexts/appState';

const ShowCPUWord = ({ children }: any) => {

  const { appState } = useAppState();
  const [ cheat, setCheat ] = useState(false);
  
  if(!appState.endGameMessage && appState.currCPUWord !== ''){
    return (
        <div>  
            { !cheat &&
              <button className="cheat" onClick={x => setCheat(!cheat)}><span>😉</span>Show me the computer's word</button>
            }

            { cheat &&
              <button className={`cheat ${(cheat)? 'on' : 'off'}`} onClick={x => setCheat(!cheat)}>Hide the computers word<br /><span>"{ appState.currCPUWord }"</span></button>
            }
        </div>
    );
  } else {
    return (null);
  }
	
};

export default ShowCPUWord;