//import { stringify } from "querystring";
import { createContext, useContext, useEffect } from "react";
import { useImmerReducer } from 'use-immer';
import { State, updateAppState, Configuration } from '../types/types';

export const GameContext = createContext<Configuration>(null as any);
export const useAppState = () => useContext(GameContext);

const initialState = {
	currCPUWord: '',
	currLetters: '',
	userToPlay: 0,
	playerCount: 1,
  	alphabet: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
  	selectedLetter: '',
	minLetters: 4, // the number of letters before win conditions are valid,
	maxLetters: 30, // the maximum letters the cpu will look for, if you get to this number user auto wins
	dictionary: null,
	endGameMessage: null
};

export const StateProvider = ({ children }: any) => {

	const [appState, updateAppState] = useImmerReducer(reducer, initialState);

	useEffect(() => {
	
		if (appState.selectedLetter !== '' && appState.dictionary != null) {

			// if a win condition is met, endTurn will return an end game messsage
			// if no win condition is met it will return null and the game continue
			let gameState = endTurn(appState);
		
			if(gameState) {
				updateAppState({type: 'set_end_game_message', value: gameState});
				return;
			}
			
			if (appState.playerCount === 1 && appState.userToPlay === 1) {	
				let word = cpuChooseWord(appState);
				console.log("cpu chooses word", word)

				if (word === '') updateAppState({type: 'set_end_game_message', value: "Well done! Computer can't think of a word"});

				// if computer cant find word, do end condition
				updateAppState({type: 'set_computer_word', value: word})
			}
		}

	
	},[appState.userToPlay]); //eslint-disable-line

	return (
		<GameContext.Provider value={{ appState, updateAppState }}>
			{children}
		</GameContext.Provider>
	);
	
};

function endTurn(appState:State){

	let endGameMessage = null;
	
	console.log("doing end turn: ", appState);

	if(appState.currLetters.length >= appState.minLetters){

		// check if player has completed a word
		let currDict = getWords(appState, appState.currLetters[0] + appState.currLetters.length)

		console.log("checking word against:", currDict.length);

		if(currDict && currDict.indexOf(appState.currLetters) !== -1){
			console.log("end game, word matched: ", appState.currLetters);
			return (appState.userToPlay === 0 && appState.playerCount === 1)? "Well done!! You have forced computer to complete the word": `Oh no Player ${appState.userToPlay}! You've completed the word`	
		}
	}
	
	let possWord = canMakeWord(appState);
	console.log("can make word:", possWord);
	if(possWord === ''){
		console.log("end game, can't find word with those letters: ", appState.currLetters);
		return (appState.userToPlay === 0 && appState.playerCount === 1)? "Well done!! the computer can't find a word with those letters": "Oh no! the letters you've chosen can't go on to make a word"
	}

	return endGameMessage;
	
}

/**
	 * I want the cpu to pick a word from the dictionary 
	 * x letters longer than the current selection.
	 * 
	 * It needs to check that if it selects the penultimate letter of that word
	 * it can't be found in the dictionary for one less
	 * 
	 */
function cpuChooseWord(appState:State){

	// check if the cpu word is already chosen, if it's still valid and won't get itself out
	if (appState.currCPUWord !== '' && appState.currCPUWord.startsWith(appState.currLetters)) {
		return appState.currCPUWord;
	}	

	let found = '';

	let alphabetCopy = [...appState.alphabet];
	alphabetCopy = shuffle(alphabetCopy);

	let inc = appState.currLetters.length + 2;
	let searchDicts = [];

	// add every second number up to the max letters for this dictionary into possible searches
	while(inc <= appState.dictionary!.maxLen){
		if (inc > appState.minLetters && inc <= appState.maxLetters) searchDicts.push(inc);
		inc += 2;
	}

	// randomise the 
	searchDicts = shuffle(searchDicts);

	console.log("searchDicts:", searchDicts);
	
	for(let i=0; i<searchDicts.length; i++){

		let dictNum = searchDicts[i];
		let dict = getWords(appState, appState.currLetters[0] + dictNum);
		
		if(dict){
			let dictCopy = [...dict];
			dictCopy = shuffle(dictCopy);
			for(let a=0; a<alphabetCopy.length; a++){	
				let nWord = appState.currLetters + alphabetCopy[a];
			
				for(let w=0; w<dictCopy.length; w++){
					
					if(dictCopy[w].startsWith(nWord)){
						if(!interimDictsContains(appState, dictCopy[w])){
							found = dictCopy[w];
							return found;
						} else {
							found = dictCopy[w];
						}
					}
				}
			}
		}
	}
	
	return found;
}

function interimDictsContains(appState:State, word: string){

	let inc = word.length - 1;
	let found = false;
	let min = Math.max(appState.currLetters.length, 2);

	console.log("interimDictsContains: ", word);
	console.log(inc, min);

	while(inc >= min){

		console.log("checking dict:", appState.currLetters[0] + inc);
		console.log("against word:", word.substring(0, inc));
		let interimDict = getWords(appState, appState.currLetters[0] + inc);
		let interimWord = word.substring(0, inc);
		if (interimDict && interimDict.indexOf(interimWord) !== -1) return true;
		inc -=2;
	}
	return found;

}

function shuffle(array: any[]) {
	let currentIndex = array.length,  randomIndex;
  
	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
  
	  // Pick a remaining element.
	  randomIndex = Math.floor(Math.random() * currentIndex);
	  currentIndex--;
  
	  // And swap it with the current element.
	  [array[currentIndex], array[randomIndex]] = [
		array[randomIndex], array[currentIndex]];
	}
  
	return array;
  }

function canMakeWord(appState:State):string{
	let found='';
	for(let i=appState.currLetters.length + 1; i<=appState.dictionary!.maxLen; i++){
		let dict = getWords(appState, appState.currLetters[0] + i);
		if(dict){;
			for(let w=0; w<dict.length; w++){
				if(dict[w].startsWith(appState.currLetters)){
					found = dict[w];
					return found;
				}
			}
		}
	}
	return found;
}

function getWords(appState:State, targetDict: string){
	return (appState.dictionary!.dict.hasOwnProperty(targetDict))? appState.dictionary!.dict[targetDict].words : null;
}

function reducer(appState:State, action:updateAppState) {

	switch (action.type) {
	  case "set_player_count":
			appState.playerCount = action.value;
		break;
	  case "set_computer_word":

			appState.selectedLetter = action.value[appState.currLetters.length];
			appState.currLetters += appState.selectedLetter;
			appState.currCPUWord = action.value
			appState.userToPlay = 1 - appState.userToPlay;
	  break;
	  case "set_selected_letter":
			
			appState.selectedLetter = action.value;
			appState.currLetters += appState.selectedLetter;
			let toPlay = 1;
			if(appState.playerCount > 1){
				toPlay = appState.userToPlay + 1;
				if(toPlay > (appState.playerCount -1)) toPlay = 0;
			}

			appState.userToPlay = toPlay;
			
		break;

	  case "set_dictionary":

			appState.dictionary = action.value;
			appState.selectedLetter = action.value.letter;
			appState.currLetters += appState.selectedLetter;

			appState.userToPlay = 1 - appState.userToPlay;
		
		break;

	  case "set_end_game_message":
			appState.endGameMessage = action.value
		break;
	
	  case "reset":
		  return initialState;
		default:
			break;
	}

}
