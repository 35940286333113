import { useCallback } from 'react'
import { useAppState } from '../contexts/appState'
import SwitchSelector from 'react-switch-selector'
import { styled } from 'styled-components'

const SelectorWrapper = styled.div`
    display:flex;
    width:100%;
    height:100%;
    vertical-align: center;
`

const options = [
    {
        label: <span>CPU</span>,
        value: 1,
        selectedBackgroundColor: "#0097e6"
    },
    {
        label: "2",
        value: 2,
        selectedBackgroundColor: "#fbc531"
    },
    {
        label: "3",
        value: 3,
        selectedBackgroundColor: "#fbc531"
    },
    {
        label: "4",
        value: 4,
        selectedBackgroundColor: "#fbc531"
    }
 ];
 

export const PlayerSelector = () => {

    const { updateAppState } = useAppState();

    const onChange = useCallback((newValue) => {
        updateAppState({type: 'set_player_count', value: newValue});
    }, [updateAppState]);
     
    return (
        <SelectorWrapper>
            <SwitchSelector
                onChange={onChange}
                options={options}
                initialSelectedIndex={0}
                backgroundColor={"#353b48"}
                fontColor={"#f5f6fa"}
            />
        </SelectorWrapper>
    );
}